import React from 'react';
import Style from './Bienvenue.module.scss';
import {Container, Row, Col} from "react-bootstrap";

export default function Bienvenue() {

    return(
        <>
            <Container>
                <Row className="justify-content-md-center">
                    <Col md="auto">
                        <h1 className={"title"}>Bienvenue sur le site des Sus-Pendus !</h1>
                    </Col>
                </Row>
            </Container>
            <Row>
                <Col className="m-2 col-lg-8 col-auto">
                    <div className={Style.text_accueil}>
                        <p><br/><br/><span className="letter">N</span>otre club de spéléo créé en 1992, regroupe des passionné(e)s
                            de spéléo et des sports connexes (cannyoning, via-ferrata, escalade, randonnée,…).</p>
                        <p><span className="letter">N</span>ous pratiquons de la spéléo en Belgique mais nous réalisons
                            également des camps à l’étranger.</p>
                        <p><span className="letter">C</span>haque semaine, nous nous retrouvons à la tour du Roton à
                            Farciennes, qui est notre local et notre site d’entrainement. Plus de 800m de cordes sont à
                            disposition avec possibilité <a href="#initiation" className="link"> d'initiation</a>.</p>
                        <p><span className="letter">S</span>i vous êtes intéressé par l'une ou l'autre de ces activités et que
                            vous voulez essayer, n’hésitez pas à nous <a className="link" href="#contact"> contacter</a> ou
                            à fouiller ce site pour plus d’informations. Nous serons toujours disponibles pour vous donner
                            toutes les informations recherchées.</p>
                    </div>
                </Col>
                <Col className="center" >
                    <div className={Style.img_accueil}>
                        <img
                            src={process.env.PUBLIC_URL  + '/img/logo.png'}
                            alt="Logo SusPendus"
                            className={Style.logo_suspendus_accueil}
                        />
                    </div>
                </Col>
            </Row>
        </>
    )

}