import './App.css';
import React, {Component} from "react";
import {BrowserRouter as Router, Route, Switch, Redirect} from 'react-router-dom';
import 'bootstrap/dist/css/bootstrap.min.css';

//Import Component
import Calendrier from "./views/Calendrier/Calendrier";
import MenuTop from "./components/menu/Menu";
import Accueil from "./views/Accueil/Accueil";
import Footer from "./components/footer/Footer";




export default class App extends Component
{
  constructor(props) {
    super(props);
    this.state = {
      connecte : true
    }
  }


    render()
  {
    return (
        <div>
          <Router>
            <MenuTop/>
            <div className="">
              <Switch>
                <Route path="/accueil" render={() => {
                  return (
                      <Accueil/>
                  )
                }}/>
                <Route path="/Calendrier"  render={() => {
                  return (
                      <Calendrier/>
                  )
                }}/>
                <Redirect to={"/accueil"}/>
              </Switch>
            </div>
            <Footer/>
          </Router>
        </div>
    );
  }

}

