import React, {Component} from "react";
import {Container, Row, Col} from "react-bootstrap";

export default class Calendrier extends Component {
    render(){

            return(
                <Container >
                    <Row className="justify-content-md-center mt-1">
                        <Col className="col-auto w-100">
                            <div className={"embed-responsive embed-responsive-1by1  "}>
                                <iframe
                                    title={"Calendrier"}
                                    className={"embed-responsive-item"}
                                    src="https://calendar.google.com/calendar/embed?height=600&wkst=2&bgcolor=%234285F4&ctz=Europe%2FBrussels&showTitle=0&showPrint=0&showTabs=1&showCalendars=0&showTz=0&src=MXV0bGx1bzd0cDNpOHJxYnVlbnRzbHFsMWtAZ3JvdXAuY2FsZW5kYXIuZ29vZ2xlLmNvbQ&color=%23C0CA33"
                                    style={{border:"solid 1px #777"}}
                                    scrolling="no"></iframe>
                            </div>

                        </Col>
                    </Row>
                </Container>
            )
        }
}