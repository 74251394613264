import React from 'react';
import {Container, Row, Col} from "react-bootstrap";
import Style from "./Accueil.module.scss";
// import des components
import Bienvenue from "./Components/Bienvenue/Bienvenue";
import Initiation from "./Components/Initiation/Initiation"
import Activite from "./Components/activites/Activite";
import Roton from "./Components/Roton/Roton";
import Contact from "./Components/Contact/Contact";

export default function Accueil() {

    return(
        <>
            <Container fluid>
                <Row className="mb-4">
                    <Bienvenue/>
                </Row>
                <Row className="mb-4 ">
                    <Col className="center">
                        <Activite/>
                    </Col>
                    <Col className={"col-lg-6 col-auto center "}>
                        <img
                            style={{height:'100%'}}
                            src={process.env.PUBLIC_URL  + '/img/activite.png'}
                            alt="Une jolie grotte"
                            className={Style.imgAccueil}
                        />
                    </Col>
                </Row>
                <Row className="mb-4">
                    <Col className={"col-lg-6 col-auto"}>
                        <Roton/>
                    </Col>
                    <Col className="center">
                        <Initiation/>
                    </Col>
                </Row>
                <Row className="mb-4">
                    <Col className="col-12">
                        <Contact/>
                    </Col>
                </Row>
            </Container>


        </>
    )

}