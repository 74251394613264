import React from 'react';
import {Card} from "react-bootstrap";
import Style from "../Initiation/Initiation.module.scss";

export default function Activite () {
    return(
        <div id="activite">
            <Card style={{border:"none"}}>
                <Card.Body className="fondBlancCasse borderArrondi">
                    <h2 className="under_title center">Nos activités</h2>
                    <Card.Title className="center"><h3 className={Style.h3_init}>Découvrez ce qui nous passionne !</h3></Card.Title>
                    <Card.Text >
                        <div className={Style.content_init}>
                            <p className={Style.text_init}>
                                <span className="letter">S</span>péléo : En tant que club spéléo, c'est bien entendu notre activité principale.
                                Visite de cavité en Belgique, France,... Exploration pour découvrire de nouvelles merveilles souterraine.
                                <br/><br/>
                                <span className="letter">T</span>echnique de corde : Pour explorer les grottes, il est essentiel de savoir progresser sur corde.
                                Grâce à notre site d'entraînement et ceux des autres clubs, nous pouvons nous entrainer et pratiquer ces techniques à l'air libre
                                hors des contraintes du milieu souterrain afin d'être parfaitement prend une fois sous terre.
                                <br/><br/>
                                <span className="letter">C</span>anyoning : A la belle saison, nous adorons prendre l'air et nous mouiller dans les beaux canyons.
                                <br/><br/>
                                <span className="letter">V</span>ia-ferrata : De l'escalade guidée par un cable nous permet de découvrir les hauteurs.
                                <br/><br/>
                                <span className="letter">E</span>scalade : Très utile pour la progression souterraine et toujours agréable.
                            </p>
                        </div>
                    </Card.Text>
                </Card.Body>
            </Card>
        </div>
    )
}