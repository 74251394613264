import React from 'react';
import {Navbar, Nav, Col} from 'react-bootstrap';
import Style from './Menu.module.scss'
import {NavLink} from 'react-router-dom'
import { NavHashLink } from 'react-router-hash-link';

export default function MenuTop() {
    return (
        <Navbar className={Style.nav} sticky="top" expand="lg">
            <Navbar.Brand>
                <NavHashLink className={Style.navBarGauche} smooth to="/1/#top">
                    Les Sus-Pendus
                </NavHashLink>
            </Navbar.Brand>
            <Navbar.Toggle aria-controls="basic-navbar-nav" />
            <Navbar.Collapse id="basic-navbar-nav al">
                <Nav className="mr-auto justify-content-md-center w-100">
                    <Col className="  center ">
                        <NavHashLink className={Style.navText} activeClassName={Style.active} to="/1"> Accueil </NavHashLink>
                    </Col>
                    <Col className="  center">
                        <NavLink className={Style.navText} activeClassName={Style.active} to="/calendrier">Calendrier</NavLink>
                    </Col>
                    <Col className=" center">
                        <NavLink className={Style.navText} activeClassName={Style.active} to={{pathname: "https://suspendus.be/piwigo/"}} target="_blank">Photos</NavLink>
                    </Col>
                </Nav>
            </Navbar.Collapse>
        </Navbar>
    );
}
