import React from 'react';
import {Card, Row, Col} from "react-bootstrap";
import Style from "../Roton/Roton.module.scss";

export default function Roton () {
    return(
        <div id="roton">
            <Card style={{border:"none"}}>
                <Card.Body className="fondBlancCasse borderArrondi">
                    <h2 className="under_title center">Notre site d'entrainement</h2>
                    <Card.Title className="center"><h3 className={Style.h3_init}>Notre camp d'entrainement !</h3></Card.Title>
                    <Card.Text >
                        <Row className="justify-content-md-center mb-4 ">
                            <Col className="center">
                                <div className={Style.content_init}>
                                    <p className={Style.text_init}>
                                        <span className="letter">L</span>a tour du Roton est un vestige du charbonnage de la région de Farcienne.
                                        <br/><br/>
                                        <span className="letter">N</span>ous avons eu la chance de pouvoir nous installer dedans et d'y créer un parcours de corde afin de nous entrainer.
                                        <br/><br/>
                                        <span className="letter">C</span>et endroit exceptionnel nous permet de nous exercer, de préparer nos expéditions, d'apprendre les techniques aux nouveaux
                                        et également de nous retrouver
                                        afin de partager nos expériences, envies, découvertes et connaissances.
                                    </p>
                                </div>
                            </Col>
                            <Col className=" col-xl-6 col-auto">
                                <img
                                    src={process.env.PUBLIC_URL  + '/img/roton.jpeg'}
                                    alt="Une jolie grotte"
                                    className={Style.imgRoton}
                                />
                            </Col>
                        </Row>

                    </Card.Text>
                </Card.Body>
            </Card>
        </div>
    )
}