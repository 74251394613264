import React from 'react';
import Style from "../menu/Menu.module.scss";
import {Col, Nav, Navbar} from "react-bootstrap";
import {NavHashLink} from "react-router-hash-link";
import {NavLink} from "react-router-dom";

const Footer = () => {
    return (
        <Navbar className={Style.nav} sticky="top" expand="lg">
            <Navbar.Brand>
                <NavHashLink className={Style.navBarGauche} smooth to="/1/#top">
                </NavHashLink>
            </Navbar.Brand>
            <Navbar.Toggle aria-controls="basic-navbar-nav" />
            <Navbar.Collapse id="basic-navbar-nav al">
                <Nav className="mr-auto justify-content-md-center w-100">
                    <Col className="  center">
                        <NavLink className={Style.navText} activeClassName={Style.active} to="/calendrier">Sus-Pendus ASBL</NavLink>
                    </Col>
                </Nav>
            </Navbar.Collapse>
        </Navbar>
    )
}
export default Footer