import React, {useRef} from 'react';
import emailjs from '@emailjs/browser';
import { Formik, Field } from 'formik';
import { Form, Col, Row } from 'react-bootstrap';
import Swal from "sweetalert2";

const FormContact = () =>  {

    const form = useRef();

    const envoyerMessage = (values, actions) =>{
        emailjs.send('service_yd38tqo', 'template_r9vxb9x', values, 'pci7K7fdaIQAX8-Ei')
            .then((result) => {
                Swal.fire(
                    'Merci !',
                    'Message envoyé, nous vous répondrons rapidement :D',
                    'success'
                )
                actions.setSubmitting(true);
            }, (error) => {
                Swal.fire(
                    'Oops !',
                    "Votre message s'est perdu dans les méandres d'internet :(",
                    'error'
                )
            });

    }
    const validerFormulaire = (values) =>{
        const errors = {};
        if (!values.email) {
            errors.email = 'Indiquer nous comment vous répondre :)';
        } else if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i.test(values.email))
        {
            errors.email = "Ceci n'est pas un e-mail valide";
        }
        if (!values.message){
            errors.message = 'Un message est nécessaire !'
        }
        if (!values.prenom){
            errors.prenom = 'Quel est votre prénom ?'
        }
        if (!values.nom){
            errors.nom = 'Quel est votre nom ?'
        }
        return errors;
    }

        return(
            <div id="contact" className="fondBlancCasse center m-3"  >
                <Formik
                    initialValues={{ email: '', message:'', nom :'', prenom: '' }}
                    validate={validerFormulaire}
                    onSubmit={envoyerMessage}
                    innerRef={form}
                >
                    {({
                          values,
                          errors,
                          touched,
                          handleChange,
                          handleBlur,
                          handleSubmit,
                          isSubmitting
                      }) => (
                        <Form onSubmit={handleSubmit}>
                            <Form.Group as={Row} controlId="formPlaintextNom">
                                <Form.Label column sm="4">
                                    Votre nom :
                                </Form.Label>
                                <Col sm="8">
                                    <input
                                        className="form-control"
                                        type="nom"
                                        name="nom"
                                        onChange={handleChange}
                                        onBlur={handleBlur}
                                        value={isSubmitting ? '' : values.nom}
                                    />
                                    {errors.nom && touched.nom && errors.nom}                                </Col>
                            </Form.Group>

                            <Form.Group as={Row} controlId="formPlaintextPrenom">
                                <Form.Label column sm="4">
                                    Votre prénom :
                                </Form.Label>
                                <Col sm="8">
                                    <input
                                        className="form-control"
                                        type="prenom"
                                        name="prenom"
                                        onChange={handleChange}
                                        onBlur={handleBlur}
                                        value={isSubmitting ? '' : values.prenom}
                                    />
                                    {errors.prenom && touched.prenom && errors.prenom}                                </Col>
                            </Form.Group>

                            <Form.Group as={Row} controlId="formPlaintextEmail">
                                <Form.Label column sm="4">
                                    Votre e-mail :
                                </Form.Label>
                                <Col sm="8">
                                    <input
                                        className="form-control"
                                        type="email"
                                        name="email"
                                        onChange={handleChange}
                                        onBlur={handleBlur}
                                        value={isSubmitting ? '' : values.email}
                                    />
                                    {errors.email && touched.email && errors.email}                                </Col>
                            </Form.Group>
                            <Form.Group as={Row} controlId="formPlaintextMessage">
                                <Form.Label column sm="4">
                                    Votre message auquel on répondra le plus vite possible :
                                </Form.Label>
                                <Col sm="8">
                                    <Field
                                        className="form-control"
                                        as="textarea"
                                        name="message"
                                        onChange={handleChange}
                                        onBlur={handleBlur}
                                        value={isSubmitting ? '' : values.message}
                                    />
                                    {errors.message && touched.message && errors.message}                                </Col>
                            </Form.Group>
                            <button type="Envoyer" disabled={isSubmitting} className="btn-primary form-control">
                                Envoyer le message
                            </button>
                        </Form>
                    )}
                </Formik>
            </div>
        )


}
export default FormContact