import React, {Component} from 'react';
import {Row, Col} from 'react-bootstrap';
import Style from './Contact.module.scss';
//import components
import FormContact from "./Components/FormContact";
export default class Contact extends Component {
    render(){
        return(
            <div id="contact" className={"fondBlancCasse borderArrondi"}>
                <h2 className="under_title center">Contact</h2>
                <p className={"center"}>Une question ? N'hésitez pas à nous contacter !</p>
                <Row>
                    <Col className="col-lg-6 col-auto h-100">
                        <FormContact/>
                    </Col>
                    <Col>
                        <div className={Style.plan} >
                            <iframe
                                    className={Style.iframe}
                                    src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2541.401951511946!2d4.535994515895648!3d50.433613296458766!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x47c2280305096b75%3A0xb0434d21b5b3801b!2sRue%20du%20Roton%2C%206240%20Farciennes!5e0!3m2!1sfr!2sbe!4v1606649236407!5m2!1sfr!2sbe"
                                    frameBorder="0"  aria-hidden="true" title="adresse"/>
                        </div>
                    </Col>
                </Row>
            </div>
        )
    }
}