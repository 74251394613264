import React from 'react';
import Style from './Initiation.module.scss'
import {Card, Row, Col} from 'react-bootstrap'

export default function Initiation() {
    return(
        <div id="initiation"  className={"fondBlancCasse center borderArrondi"} >
            <Card style={{border:"none"}} >
                <Card.Body className="fondBlancCasse">
                    <h2 className="under_title center">Initiation</h2>
                    <Card.Title className="center"><h3 className={Style.h3_init}>Vous désirez découvrir notre passion ?</h3></Card.Title>
                    <Card.Text >
                        <div className={Style.content_init}>
                            <p className={Style.text_init}>
                                <span className="letter">N</span>otre club organise régulièrement des activités d'un niveau qui
                                s'adapte aux participants.<br/><br/>
                                <span className="letter">P</span>lusieurs cadres ADEPS au sein du club sont là pour guider les
                                premiers pas des débutants et aider au perfectionnement de ceux-ci. Notre but étant de rendre chaque
                                membre du club autonome.<br/><br/>
                                <span className="letter">S</span>i vous désirez devenir membre ou simplement venir découvrir la spéléo, il n'y a rien de plus facile :
                                Prenez <a className="link" href="#contact"> contact</a> avec un administrateur qui se fera un
                                devoir de vous expliquer les démarches à accomplir.
                            </p>
                        </div>
                        <Row>
                            <Col className="col-lg-3 col-auto">
                                <a href={process.env.PUBLIC_URL + "/documents/cotisation_club.pdf"} target="_blank"  rel="noreferrer" className="link">
                                    <figure>
                                        <img src={process.env.PUBLIC_URL + "/img/file.png"} alt="Montant cotisation" className={Style.img_doc}/>
                                            <figcaption className="center">Montant cotisation</figcaption>
                                    </figure>
                                </a>
                            </Col>
                            <Col className="col-lg-3 col-auto">
                                <a href={process.env.PUBLIC_URL + "/documents/fiche_affiliation_UBS.pdf"} target="_blank"  rel="noreferrer" className="link">
                                    <figure>
                                        <img src={process.env.PUBLIC_URL + "/img/file.png"} alt="Fiche affiliation" className={Style.img_doc}/>
                                        <figcaption className="center">Fiche d'affiliation</figcaption>
                                    </figure>
                                </a>
                            </Col>
                            <Col className="col-lg-3 col-auto">
                                <a href={process.env.PUBLIC_URL + "/documents/attestation_medicale.pdf"} target="_blank"  rel="noreferrer" className="link">
                                    <figure>
                                        <img src={process.env.PUBLIC_URL + "/img/file.png"} alt="Fiche médicale" className={Style.img_doc}/>
                                        <figcaption className="center">Fiche médicale</figcaption>
                                    </figure>
                                </a>
                            </Col>
                            <Col className="col-lg-3 col-auto">
                                <a href={process.env.PUBLIC_URL + "/documents/roi.pdf"} target="_blank"  rel="noreferrer" className="link">
                                    <figure>
                                        <img src={process.env.PUBLIC_URL + "/img/file.png"} alt="Montant cotisation" className={Style.img_doc}/>
                                        <figcaption className="center">R.O.I</figcaption>
                                    </figure>
                                </a>
                            </Col>
                        </Row>
                    </Card.Text>
                </Card.Body>
            </Card>
        </div>
    )
}